#root {
  text-align: center;
}

#app {
  text-align: center;
}

.bc-embed {
  border: 0;
  width: 350px;
  height: 687px;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: green;
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

main {
  padding: 1rem;
}

article {
  padding: 1rem;
}

ul {
  list-style-type: none;
}

thead {
  font-weight: bold;
}

table {
  margin: auto;
  padding: 1rem;
}

.awards, .projects, .press {
  text-align: left;
  display: inline-grid;
}

.discography-table td {
  border: 1px solid black;
}

.me {
  width: 300px;
}

body {
  font-family: 'Courier New', Courier, monospace;
}